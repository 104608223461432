<template src="./LimitFilter.html"></template>
<style scoped lang="scss" src="./LimitFilter.scss"></style>

<script>
import { FilterLimit } from "../../constants";

const Max = 999999999

export default {
  name: 'LimitFilter',
  data: function () {
    return {
      selected: FilterLimit.firstLimit,
      options: [
        { label: FilterLimit.firstLimit },
        { label: FilterLimit.secondLimit },
        { label: FilterLimit.thirdLimit },
        { label: FilterLimit.fulLimit },
      ]
    }
  },
  props: {
    isLoading: Boolean,
    limit: [String, Number],
    total: [String, Number]
  },

  watch: {
    limit: function (value) {
      this.selected = value === Max ? FilterLimit.fulLimit : value
    }
  },
  methods: {
    onChange(value) {
      const limit = value === FilterLimit.fulLimit ? Max : Number(value)
      this.$emit('change', limit)
    }
  }
}
</script>
